import React from "react";
import Logo from "../Assets/EasyArcImg.png";
import image from "../Assets/Homeimage.png";

const ComingSoon = () => {
  return (
    <>
      <section className="coming-soon-lg bg-black w-screen h-dvh relative">
        <div className="relative z-10 left  h-full flex items-center p-14">
          <img src={Logo} alt="" className="fixed top-14 left-14" />

          <div className="flex  flex-col justify-start ">
            <h1 className=" font-bold xl:text-[94px] lg:text-[85px] md:text-[70px] text-[#FFBB00] ">
              COMING SOON
            </h1>
            <p className="text-[18px] text-[#FFFFFF] mt-[-10px]">
              Your path to transparent property deals
            </p>
            <div className=" mt-2 max-w-[667px] bg-[#3D3D3D]  py-2 relative rounded-md flex">
              <input
                type="text"
                placeholder="Enter your Address"
                className="bg-[#3D3D3D] p-1 ms-5 w-[80%] text-white"
              />
              <button className="bg-[#FFBB00] p-1 absolute right-2 rounded-md px-2">
                Notify me
              </button>
            </div>
          </div>
        </div>
      </section>

      <section className="coming-soon-sm bg-black w-screen h-dvh relative">
        <div className="relative z-10 left  h-full flex items-center p-5 ">
          <img src={Logo} alt="" className="fixed top-6 left-6" />

          <div className="flex  flex-col justify-start gap-4 ">
            <h1 className=" font-bold text-[48px] text-[#FFBB00] ">
              COMING SOON
            </h1>
            <p className="text-[18px] text-[#FFFFFF] mt-[-10px]">
              Your path to transparent property deals
            </p>
            <div className=" mt-2 max-w-[667px] bg-[#3D3D3D]  py-2 relative rounded-md flex">
              <input
                type="text"
                placeholder="Enter your Address"
                className="bg-[#3D3D3D] p-1 ms-5 w-[80%] text-white"
              />
              <button className="bg-[#FFBB00] p-1 absolute right-2 rounded-md px-2">
                Notify me
              </button>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ComingSoon;

import "./App.css";
import ComingSoon from "./components/comingSoon";

function App() {
  return (
    <>
      <ComingSoon />
    </>
  );
}

export default App;
